@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.leaflet-container {
    width: 90%;
    height: 500px;
    margin: 10px;
}

.text_marker {
    float: left;
    text-align: center;
}

.text_marker_text {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    transform: translate(-45%, 30%);
    min-width: 10em;
}

a {
    color: blue;
    text-decoration: underline;
}
